import React, { useState, useEffect } from 'react';
import {  Nav, Tab } from 'react-bootstrap';
import { userFactory } from '../../services/PostsService';
import tabDataUsers from './subtabs/tabDataUsers.json'

const tabData = tabDataUsers

function User() {
    const [product, setProduct] = useState('');
    const [productId, setProductId] = useState(null);
    const [toggle, setToggle] = useState(false);
    const [Data, setData] = useState({
        Name: 'Pipoca Amarela',
        Weight: 15,
        SalePrice: 1.5,
        CostPrice: 1.5,
        Height: 10,
        Width: 6,
        Length: 0.4,
        Store: null,
        Description: 'Prepare-se para uma aventura saborosa com a Pipoca Amarela Kero Mais! Este pacote de 15g é a companhia perfeita para uma tarde cheia de diversão. Com seu vibrante amarelo e um sabor que faz o paladar dançar de alegria, cada mordida é uma explosão de felicidade. Crocante, deliciosa e cheia de magia, a Pipoca Amarela é a escolha certa para crianças que adoram um lanche especial e cheio de cor.'
    });

    useEffect(() => {
        if (productId === null) {
          const checkProduct = async () => {
            try {
              const params = new URLSearchParams(window.location.search);
              const pValue = params.get('p');
              if (pValue) {
                setProductId(pValue)
                const fetchProducts = async () => {
                  try {
                    const response = await userFactory( pValue);
                    if (response.status === 200) {
                        setProduct(response.data.result)
                    } else {
                        console.error('Erro ao obter os produtos:', response.statusText);
                    }
                  } catch (error) {
                    console.error('Erro ao obter os produtos:', error);
                  }
                };
                fetchProducts();
              } else {
                console.log('O parâmetro "p" não está presente na URL ou seu valor é vazio.');
              }
            } catch (error) {
              console.error('Erro ao obter o valor de "p" da URL:', error);
            }
          };
          checkProduct();
        }
    }, [productId]);

    return (
        <>
            <div className="custom-tab-1 pb-3">
                <Tab.Container>
                    <Nav as="ul" className="navigatore justify-content-center">
                        {tabData.map((data, i) => (
                            <Nav.Item as="li" key={i}>
                                <Nav.Link eventKey={data.name.toLowerCase()} href={data.link}>
                                    <i className={`la la-${data.icon} me-2`} />
                                    {data.name}
                                </Nav.Link>
                            </Nav.Item>
                        ))}
                    </Nav>
                </Tab.Container>
            </div>
            <div className="row">
                <div className="col-xl-12 pt-3">
                    {
                        product !== '' ?
                            <div id="customers"
                                className="row"
                                style={{
                                    width: "100%",
                                    padding: 0,
                                    justifyContent: 'center'
                                }}
                            >
                                <div className="col-12"
                                    style={{
                                        marginBottom: 6,
                                        borderRadius: 6,
                                    }}>
                                    <div className="p-3">
                                        <span style={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
                                            <div className="media d-flex flex-column justify-content-between align-items-center">
                                                <div className="media d-flex flex-row">
                                                    {
                                                        product.images.length === 0 ? (
                                                            <span style={{
                                                            width: '100%',
                                                            height: 120,
                                                            backgroundImage: "gray",
                                                            margin: '0 auto',
                                                            display: 'flex',
                                                            alignItems: 'center',
                                                            justifyContent: 'center', gap: 2, backgroundColor: 'rgb(241 241 241)'
                                                            }}>
                                                            <i className='bi bi-shop' style={{ fontSize: 45, color: 'white' }} />
                                                        </span>
                                                        ) : <img className="" style={{ width: 120 , margin: 10 }} src={'https://core.pskeromais.com.br/' + product.images[product.images.length - 1].url} />
                                                    }
                                                    <span className="media d-flex flex-column">
                                                        <h1 className="mb-0 fs--1" style={{padding: '15px 0px'}}>
                                                            {product.name}
                                                        </h1>
                                                        <span style={{border: '1px #dedede solid' , borderRadius: '6px', padding: '6px 15px' , display: 'flex' , flexDirection: 'column' , marginBottom: '10px' }}>
                                                            <span style={{padding: 0 }}><span style={{color: '#f54749' }}>CPF:</span> <b>{product.taxId}</b></span>
                                                            <span style={{padding: 0 }}><span style={{color: '#f54749'}}>EMAIL:</span> <b>{product.email}</b></span>
                                                            <span style={{padding: 0 }}><span style={{color: '#f54749'}}>TELEFONE:</span> <b>{product.phone}</b></span>
                                                        </span>
                                                    </span>
                                                </div>
                                                <div className="media d-flex mt-3" style={{gap: 4}}>
                                                    <a href={`/consumidor?p=${Data.id}`}  className='btn btn-primary' style={{ border: 0 , padding: '7px'}}>
                                                        <i className="bi bi-chat-text"></i> Pedidos
                                                    </a>  
                                                </div>
                                            </div>
                                        </span>
                                    </div>
                                </div>
                            </div>
                            :
                            ' produto não encontrado '
                    }
                </div>
            </div>
        </>
    )
}
export default User;