import React, { useState, useEffect } from 'react';
import { Nav, Tab } from 'react-bootstrap';
import { usersFactory } from '../../services/PostsService';
import users from './subtabs/tabDataUsers.json'
import SimplePagination from "../components/pagination/Paginations"

const tabData = users

function CustomersList() {
  const [consumers, setConsumers] = useState([]);
  const [trigger, setTrigger] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, setTotalItems] = useState(null);

  useEffect(() => {
    if (consumers.length === 0 && !trigger) {
      const fetchProducts = async () => {
        try {
          const response = await usersFactory(1, 20);
          if (response.status === 200) {
            setConsumers(response.data.result);
            setTotalItems(response.data.totalPage)
          } else {

          }
        } catch (error) {

        }
      };
      setTrigger(true)
      fetchProducts();
    }
  }, [consumers]);


  const handlePageChange = (page) => {
    const fetchShoppers = async () => {
      try {
        const response = await usersFactory(page, 20);
        if (response.status === 200) {
          setConsumers(response.data.result);
          setTotalItems(response.data.totalPage)
          setCurrentPage(page)
        } else {

        }
      } catch (error) {

      }
    };
    fetchShoppers();
  };

  return (
    <>
      <div className="custom-tab-1 pb-3">
        <Tab.Container defaultActiveKey={tabData[0].name.toLowerCase()}>
          <Nav as="ul" className="navigatore justify-content-center">
            {tabData.map((data, i) => (
              <Nav.Item as="li" key={i}>
                <Nav.Link eventKey={data.name.toLowerCase()} href={data.link}>
                  <i className={`la la-${data.icon} me-2`} />
                  {data.name}
                </Nav.Link>
              </Nav.Item>
            ))}
          </Nav>
        </Tab.Container>
      </div>
      <div className="row">
        <div className="col-xl-12 pt-3">
          <div>
            <div className="table mb-0">
              <div id="customers"
                className="row"
                style={{
                  width: "100%",
                  padding: 0,
                  justifyContent: 'center'
                }}
              >
                <ul className="btn-reveal-trigger row" style={{
                  width: "100%",
                  padding: 0
                }}>
                  {consumers.map((Data, index) => (

                    <li className="col-md-4"
                      style={{
                        marginBottom: 6,
                        borderRadius: 6,
                      }} key={index}>
                      <div
                        className="p-3"
                        style={{
                          boxShadow: '2px 2px 2px #f1f1f1',
                        }}>
                        <span style={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
                          {
                            Data.images.length === 0 ? (
                              <span style={{
                                width: '100%',
                                height: 120,
                                backgroundImage: "gray",
                                margin: '0 auto',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center', gap: 2, backgroundColor: 'rgb(241 241 241)'
                              }}>
                                <i className='bi bi-shop' style={{ fontSize: 45, color: 'white' }} />
                              </span>
                            ) : <img className="" style={{ width: '100%' }} src={'https://core.pskeromais.com.br/' + Data.images[Data.images.length - 1].url} />
                          }
                          <div className="media d-flex align-items-center">
                            <div>
                              <br />
                              <div className="media d-flex justify-content-between">
                                <h3 className="mb-0 fs--1">
                                  {Data.name}
                                </h3>
                              </div>
                            </div>
                          </div>
                          <span style={{ display: 'flex', flexDirection: 'column', gap: 4 }}>
                            <span className='d-flex flex-wrap flex-column'>
                              <span style={{ color: 'rgb(245, 71, 73)', wordBreak: 'break-word' }}>
                                <i className="bi bi-headset"></i> contatos
                              </span>
                              <ul>
                                <li style={{ fontSize: '15px', fontWeight: 'bold', wordBreak: 'break-word' }}>
                                  {Data.email}
                                </li>
                                <li style={{ fontSize: '15px', fontWeight: 'bold', wordBreak: 'break-word' }}>
                                  {Data.phone}
                                </li>
                              </ul>
                            </span>
                          </span>
                        </span>
                        <hr style={{ margin: '8px 0' }} />
                        <span style={{ display: 'flex', flexDirection: 'row', gap: 1 }}>
                          <a href={`/consumidor?p=${Data.id}`} className="btn btn-primary py-1 px-3 mt-2" style={{ maxWidth: 100 }}>
                            ver
                          </a>
                          <a href={`/pedidos-cliente?p=${Data.id}`} className="btn btn-secondary py-1 px-3 mt-2" style={{ maxWidth: 100 }}>
                            pedidos
                          </a>
                        </span>
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="col-12">
          {
            consumers.length > 0 ?
              <SimplePagination
                totalItems={totalItems}
                currentPage={currentPage}
                onPageChange={handlePageChange}
              />
              : null
          }
        </div>
      </div>
    </>
  )
}

export default CustomersList;