import React, { useState, useEffect } from 'react';
import { Nav, Tab } from 'react-bootstrap';
import { Link, Pagination } from 'react-router-dom';
import { Field } from "formik";
import * as Yup from "yup";
import tabDataProducts from './subtabs/tabDataProducts.json'
import { createProduct } from '../../services/PostsService';
import bgimage from '../../images/no-img-avatar.png';


const tabData = tabDataProducts

function ProductForm() {
    const [image, setImage] = useState(null);
    const [product, setProduct] = useState({
        CostPrice: "",
        Description: "",
        Height: "",
        Length: "",
        Name: "",
        SalePrice: "",
        Weight: "",
        Width: "",
        categoryId: "54bd475f-7438-49fb-9967-ef0d2ba32c46"
    })
    const [file, setFile] = useState(null);
    const [errorMessage, setErrorMessage] = useState("");
    const [base64Image, setBase64Image] = useState(null);

    const handleProductFormChange = (e) => {
        const { name, value } = e.target;

        setProduct((prevData) => {
            return {
                ...prevData,
                [name]: value,
            };
        });
    };

    const handleImageChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setImage(reader.result);
            };
            reader.readAsDataURL(file);
        }
    };

    const CustomHandleSubmit = async (e) => {

        try {
            const createProductFunc = async () => {
                try {
                    const response = await createProduct(product);
                    if (response.status === 200) {
                        setProduct(response.data.result);
                    } else {

                    }
                } catch (error) {

                }
            };
            createProductFunc()
        } catch (error) {
            console.error('Error uploading data:', error);
        }
    };

    const formHandler = (value, id) => {
        const newProductDummy = { ...product, [id]: value[id] };
        setProduct(newProductDummy);
    };

    const fileHandler = (e) => {
        const file = e.target.files[0];
        if (file && file.size < 1048576) {
            setFile(file);
            const reader = new FileReader();
            reader.onloadend = () => {
                setBase64Image(reader.result);
            };
            reader.readAsDataURL(file)
        } else {
            setErrorMessage("A imagem deve ter menos de 1MB.");
            setFile(null);
            setBase64Image(null);
        }
    };

    const RemoveImage = () => {
        setFile(null);
        setBase64Image(null);
    };

    useEffect(() => {
        if (file) {
            if (base64Image) {
                const cleanBase64 = base64Image.replace(/^data:image\/[a-z]+;base64,/, '');

                setProduct((prevData) => ({
                    ...prevData,
                    Images: [{
                        Name: 'Imagem Pessoal',
                        Position: 'Profile',
                        IsMain: true,
                        Base64: cleanBase64,
                    }]
                }));
            }
        }
    }, [base64Image]);


    useEffect(() => {
        console.log('---> product <---')
        console.log(product)
    }, [product]);

    return (
        <>
            <div className="custom-tab-1 pb-3">
                <Tab.Container>
                    <Nav as="ul" className="navigatore justify-content-center">
                        {tabData.map((data, i) => (
                            <Nav.Item as="li" key={i}>
                                <Nav.Link eventKey={data.name.toLowerCase()} href={data.link}>
                                    <i className={`la la-${data.icon} me-2`} />
                                    {data.name}
                                </Nav.Link>
                            </Nav.Item>
                        ))}
                    </Nav>
                </Tab.Container>
            </div>
            <span
                style={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    justifyContent: 'spaceBetween',
                    width: '100%',
                    margin: '20px 0',
                    justifyContent: 'space-around'
                }}
            >
                <h5>Cadastrar Produto</h5> <hr style={{ width: 'Calc(100% - 243px)' }} /></span>
            <div></div>
            <div className='col-12'>

                <div className="avatar-upload d-flex align-items-center justify-content-center">
                    <div className="change position-relative d-flex">
                        <div className="avatar-preview" style={{ maxWidth: 120 }}>
                            {

                                <img
                                    id="saveImageFile"
                                    src={file ? URL.createObjectURL(file) : bgimage}
                                    alt={file ? file.name : "Imagem Padrão"}
                                    style={{ maxWidth: 120 }}
                                />

                            }

                        </div>
                        <div className="change-btn d-flex align-items-center flex-wrap">
                            <input
                                type='file'
                                onChange={fileHandler}
                                className="form-control"
                                id="imageUpload"
                                accept=".png, .jpg, .jpeg"
                            />
                            <div className="change-btn d-flex flex-column flex-wrap gap-4">
                                <label htmlFor="imageUpload" className="dlab-upload">{product && product.images && product.images.length > 0 ? ("Escolher Outro Arquivo") : "Escolher Arquivo"}</label>
                                <Link to={"#"} className="btn remove-img ms-2 bg-light" onClick={RemoveImage}>Remover</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <form className="row">
                <div className="mb-1 col-12">
                    <h4 style={{ color: '#f54749' }}><i className="bi bi-box-seam"></i> Produto</h4>
                </div>
                <div className="form-group mb-1 col-md-6">
                    <div className="form-group mb-3">
                        <label className="mb-1" style={{ display: 'inline' }}>
                            <strong>NOME DO PRODUTO</strong>
                        </label>
                        <input
                            name="Name"
                            className="form-control"
                            placeholder="..."
                            style={{
                                width: '100%',
                                border: '1px solid initial',
                            }}
                            onChange={handleProductFormChange}
                        />
                    </div>
                </div>
                <div className="form-group mb-1 col-md-6">
                    <div className="form-group mb-3">
                        <label className="mb-1" style={{ display: 'inline' }}>
                            <strong>PESO</strong>
                        </label>
                        <input
                            name="Weight"
                            className="form-control"
                            placeholder="..."
                            style={{
                                width: '100%',
                                border: '1px solid initial',
                            }}
                            onChange={handleProductFormChange}
                        />
                    </div>
                </div>
                <div className="form-group mb-1 col-md-6">
                    <div className="form-group mb-3">
                        <label className="mb-1" style={{ display: 'inline' }}>
                            <strong>TIPO</strong>
                        </label>
                        <div className="form-group mb-3">
                            <select
                                name="CategoryId"
                                defaultValue={"option"}
                                className="form-control"
                                value={product.CategoryId}
                            >
                                <option>1</option>
                                <option>2</option>
                                <option>3</option>
                                <option>4</option>
                            </select>
                        </div>
                    </div>
                </div>
                <div className="form-group mb-1">
                    <div className="form-group mb-3">
                        <label className="mb-1" style={{ display: 'inline' }}>
                            <strong>DESCRIÇÃO</strong>
                        </label>
                        <textarea
                            name="Description"
                            className="form-control"
                            rows="4"
                            style={{
                                width: '100%',
                                border: '1px solid initial',
                                minHeight: 120
                            }}
                            onChange={handleProductFormChange}
                        />
                    </div>
                </div>
                <div className="mb-1 col-12">
                    <h4 style={{ color: '#f54749' }}><i className="bi bi-arrows-angle-expand"></i> Dimensões</h4>
                </div>
                <div className="form-group mb-1 col-md-6">
                    <div className="form-group mb-3">
                        <label className="mb-1" style={{ display: 'inline' }}>
                            <strong>ALTURA</strong>
                        </label>
                        <input
                            name="Height"
                            className="form-control"
                            placeholder="..."
                            style={{
                                width: '100%',
                                border: '1px solid initial',
                            }}
                            onChange={handleProductFormChange}
                        />
                    </div>
                </div>
                <div className="form-group mb-1 col-md-6">
                    <div className="form-group mb-3">
                        <label className="mb-1" style={{ display: 'inline' }}>
                            <strong>LARGURA</strong>
                        </label>
                        <input
                            name="Width"
                            className="form-control"
                            placeholder="..."
                            style={{
                                width: '100%',
                                border: '1px solid initial',
                            }}
                            onChange={handleProductFormChange}
                        />
                    </div>
                </div>
                <div className="form-group mb-1 col-md-6">
                    <div className="form-group mb-3">
                        <label className="mb-1" style={{ display: 'inline' }}>
                            <strong>COMPRIMENTO</strong>
                        </label>
                        <input
                            name="Length"
                            type="textarea"
                            className="form-control"
                            placeholder="..."
                            style={{
                                width: '100%',
                                border: '1px solid initial',
                            }}
                            onChange={handleProductFormChange}
                        />
                    </div>
                </div>
                <div className="mb-1 col-12">
                    <h4 style={{ color: '#f54749' }}><i className="bi bi-wallet2"></i> Venda</h4>
                </div>
                <div className="form-group mb-1 col-md-6">
                    <div className="form-group mb-3">
                        <label className="mb-1" style={{ display: 'inline' }}>
                            <strong>PREÇO DE CUSTO</strong>
                        </label>
                        <input
                            name="CostPrice"
                            type="textarea"
                            className="form-control"
                            placeholder="..."
                            style={{
                                width: '100%',
                                border: '1px solid initial',
                            }}
                            onChange={handleProductFormChange}
                        />
                    </div>
                </div>
                <div className="form-group mb-1 col-md-6">
                    <div className="form-group mb-3">
                        <label className="mb-1" style={{ display: 'inline' }}>
                            <strong>PREÇO DE VENDA</strong>
                        </label>
                        <input
                            name="SalePrice"
                            type="textarea"
                            className="form-control"
                            placeholder="..."
                            style={{
                                width: '100%',
                                border: '1px solid initial',
                            }}
                            onChange={handleProductFormChange}
                        />
                    </div>
                </div>
            </form>

            <button className="btn btn-primary mt-3 mb-3" onClick={() => CustomHandleSubmit()}>CADASTAR PRODUTO</button>
        </>
    )
}
export default ProductForm;