import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Nav, Tab } from 'react-bootstrap';
import bgimage from '../../images/no-img-avatar.png';
import { productFactory, productUpdateFactory } from '../../services/PostsService';
import tabDataProducts from './subtabs/tabDataProducts.json'

const tabData = tabDataProducts

function ProductEdit() {
    const [file, setFile] = useState(null);
    const [base64Image, setBase64Image] = useState(null);
    const [image, setImage] = useState(null);
    const [product, setProduct] = useState(
        {
            images: []
        }
    );
    const [productId, setProductId] = useState(null);
    const [toggle, setToggle] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");

    const fileHandler = (e) => {
        const file = e.target.files[0];
        if (file && file.size < 1048576) {
            setFile(file);
            const reader = new FileReader();
            reader.onloadend = () => {
                setBase64Image(reader.result);
            };
            reader.readAsDataURL(file)
        } else {
            setErrorMessage("A imagem deve ter menos de 1MB.");
            setFile(null);
            setBase64Image(null);
        }
    };

    const RemoveImage = () => {
        setFile(null);
        setBase64Image(null);
    };

    const formHandler = (values, id) => {
        const newProduct = { ...product }
        newProduct[id] = values
        setProduct(newProduct)
    };

    const editProduct = (newImage) => {
        if(!file) {
            product.images = product.images.map(image => ({
                ...image,
                isMain: false
            }));
            const updatedImages = [
                ...product.images,
                {
                    ...newImage,
                    position: product.images.length,
                    isMain: true                      
                }
            ];        
            product.images = updatedImages;
        }
        productUpdateFactory(product);
    };
    

    useEffect(() => {
        if (!productId) {
            const params = new URLSearchParams(window.location.search);
            const pValue = params.get('p');
            const checkProduct = async () => {
                try {
                    if (pValue) {
                        setProductId(pValue)
                        setToggle(true)
                    } else {
                    }
                } catch (error) {

                }
            };
            const fetchProducts = async () => {
                try {
                    const response = await productFactory(pValue);
                    if (response.status === 200) {
                        setProduct(response.data.result);
                        setToggle(false)
                    } else {
                        console.error('Erro ao obter os produtos:', response.statusText);
                        setToggle(false)
                    }
                } catch (error) {
                    console.error('Erro ao obter os produtos:', error);
                    setToggle(false)
                }
            };
            checkProduct();
            fetchProducts();
        }
    }, [productId]);

    return (
        <>
            <div className="custom-tab-1 pb-3">
                <Tab.Container defaultActiveKey={tabData[0].name.toLowerCase()}>
                    <Nav as="ul" className="navigatore justify-content-center">
                        {tabData.map((data, i) => (
                            <Nav.Item as="li" key={i}>
                                <Nav.Link eventKey={data.name.toLowerCase()} href={data.link}>
                                    <i className={`la la-${data.icon} me-2`} />
                                    {data.name}
                                </Nav.Link>
                            </Nav.Item>
                        ))}
                    </Nav>
                </Tab.Container>
            </div>
            <span
                style={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    justifyContent: 'spaceBetween',
                    width: '100%',
                    margin: '20px 0',
                    justifyContent: 'space-around'
                }}
            >
                <h3>Editar Produto</h3> <hr style={{ width: 'Calc(100% - 243px)' }} /></span>
            <div></div>
            <>
                <div className='row'>
                    <div className='col-12'>
                        <br />
                        <h5><i className="bi bi-card-image" style={{ color: '#f54749' }}></i> Imagem de exibição</h5>
                        <hr />
                    </div>
                    <div className='col-12'>
                        {product.images.length === 0?(
                            <div className="avatar-upload d-flex align-items-center justify-content-center">
                                <div className="change position-relative d-flex">
                                    <div className="avatar-preview" style={{ maxWidth: 120 }}>
                                        {
                                            <img
                                                id="saveImageFile"
                                                src={file ? URL.createObjectURL(file) : bgimage}
                                                alt={file ? file.name : "Imagem Padrão"}
                                                style={{ maxWidth: 120 }}
                                            />
                                        }
                                    </div>
                                    <div className="change-btn d-flex align-items-center flex-wrap">
                                        <input
                                            type='file'
                                            onChange={fileHandler}
                                            className="form-control"
                                            id="imageUpload"
                                            accept=".png, .jpg, .jpeg"
                                        />
                                        <div className="change-btn d-flex flex-column flex-wrap gap-4">
                                            <label htmlFor="imageUpload" className="dlab-upload">{product && product.images && product.images.length > 0 ? ("Escolher Outro Arquivo") : "Escolher Arquivo"}</label>
                                            <Link to={"#"} className="btn remove-img ms-2 bg-light" onClick={RemoveImage}>Remover</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>)
                            :
                            <div className="avatar-upload d-flex align-items-center justify-content-center">
                                <div className="change position-relative d-flex">
                                    <div className="avatar-preview" style={{ maxWidth: 220 }}>
                                        {
                                            <img
                                                id="saveImageFile"
                                                src={file ? URL.createObjectURL(file) : 'https://core.pskeromais.com.br/' + product.images[0].url}
                                                alt={file ? file.name : "Imagem Padrão"}
                                                style={{ maxWidth: 220 }}
                                            />
                                        }
                                    </div>
                                    <div className="change-btn d-flex align-items-center flex-wrap">
                                        <input
                                            type='file'
                                            onChange={fileHandler}
                                            className="form-control"
                                            id="imageUpload"
                                            accept=".png, .jpg, .jpeg"
                                        />
                                        <div className="change-btn d-flex flex-column flex-wrap gap-4">
                                            <label htmlFor="imageUpload" className="dlab-upload">{product && product.images && product.images.length > 0 ? ("Escolher Outro Arquivo") : "Escolher Arquivo"}</label>
                                            <Link to={"#"} className="btn remove-img ms-2 bg-light" onClick={RemoveImage}>Remover</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }

                    </div>
                </div>
                <form className="row">
                    <div className="mb-1 col-12">
                        <h4 style={{ color: '#f54749' }}><i className="bi bi-box-seam"></i> Produto</h4>
                    </div>
                    <div className="form-group mb-1 col-md-6">
                        <div className="form-group mb-3">
                            <label className="mb-1" style={{ display: 'inline' }}>
                                <strong>NOME DO PRODUTO</strong>
                            </label>
                            <input
                                className="form-control"
                                placeholder="..."
                                style={{
                                    width: '100%',
                                    border: '1px solid initial',
                                }}
                                id="name"
                                onChange={(event) => formHandler(event.target.value, event.target.id)}
                                value={product.name ? product.name : ''}
                            />
                        </div>
                    </div>
                    <div className="form-group mb-1 col-md-6">
                        <div className="form-group mb-3">
                            <label className="mb-1" style={{ display: 'inline' }}>
                                <strong>PESO</strong>
                            </label>
                            <input
                                className="form-control"
                                placeholder="..."
                                style={{
                                    width: '100%',
                                    border: '1px solid initial',
                                }}
                                id="weight"
                                onChange={(event) => formHandler(event.target.value, event.target.id)}
                                value={product.weight ? product.weight : ''}
                            />
                        </div>
                    </div>
                    <div className="form-group mb-1 col-md-6">
                        <div className="form-group mb-3">
                            <label className="mb-1" style={{ display: 'inline' }}>
                                <strong>Categoria</strong>
                            </label>
                            <div className="form-group mb-3">
                                <select
                                    name={'categoryId'}
                                    defaultValue={"option"}
                                    className="form-control"
                                    id="categoryId"
                                    onChange={(event) => formHandler(event.target.value, event.target.id)}
                                    value={product.categoryId ? product.categoryId : ''}
                                >
                                    <option>1</option>
                                    <option>2</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div className="form-group mb-1">
                        <div className="form-group mb-3">
                            <label className="mb-1" style={{ display: 'inline' }}>
                                <strong>DESCRIÇÃO</strong>
                            </label>
                            <textarea
                                className="form-control"
                                rows="4"
                                id="description"
                                value={product.description ? product.description : ''}
                                onChange={(event) => formHandler(event.target.value, event.target.id)}
                                style={{
                                    width: '100%',
                                    border: '1px solid initial',
                                    minHeight: 120
                                }}

                            />
                        </div>
                    </div>
                    <div className="mb-1 col-12">
                        <h4 style={{ color: '#f54749' }}><i className="bi bi-arrows-angle-expand"></i> Dimensões</h4>
                    </div>
                    <div className="form-group mb-1 col-md-6">
                        <div className="form-group mb-3">
                            <label className="mb-1" style={{ display: 'inline' }}>
                                <strong>ALTURA</strong>
                            </label>
                            <input
                                className="form-control"
                                placeholder="..."
                                value={product.height ? product.height : ''}
                                id="height"
                                onChange={(event) => formHandler(event.target.value, event.target.id)}
                                style={{
                                    width: '100%',
                                    border: '1px solid initial',
                                }}

                            />
                        </div>
                    </div>
                    <div className="form-group mb-1 col-md-6">
                        <div className="form-group mb-3">
                            <label className="mb-1" style={{ display: 'inline' }}>
                                <strong>LARGURA</strong>
                            </label>
                            <input
                                className="form-control"
                                placeholder="..."
                                value={product.width ? product.width : ''}
                                id="width"
                                onChange={(event) => formHandler(event.target.value, event.target.id)}
                                style={{
                                    width: '100%',
                                    border: '1px solid initial',
                                }}

                            />
                        </div>
                    </div>
                    <div className="form-group mb-1 col-md-6">
                        <div className="form-group mb-3">
                            <label className="mb-1" style={{ display: 'inline' }}>
                                <strong>COMPRIMENTO</strong>
                            </label>
                            <input
                                type="textarea"
                                className="form-control"
                                placeholder="..."
                                value={product.length ? product.length : ''}
                                id="length"
                                onChange={(event) => formHandler(event.target.value, event.target.id)}
                                style={{
                                    width: '100%',
                                    border: '1px solid initial',
                                }}

                            />
                        </div>
                    </div>
                    <div className="mb-1 col-12">
                        <h4 style={{ color: '#f54749' }}><i className="bi bi-wallet2"></i> Venda</h4>
                    </div>
                    <div className="form-group mb-1 col-md-6">
                        <div className="form-group mb-3">
                            <label className="mb-1" style={{ display: 'inline' }}>
                                <strong>PREÇO DE CUSTO</strong>
                            </label>
                            <input
                                type="textarea"
                                className="form-control"
                                value={product.salePrice ? product.salePrice : ''}
                                placeholder="..."
                                id="salePrice"
                                onChange={(event) => formHandler(event.target.value, event.target.id)}
                                style={{
                                    width: '100%',
                                    border: '1px solid initial',
                                }}

                            />
                        </div>
                    </div>
                    <div className="form-group mb-1 col-md-6">
                        <div className="form-group mb-3">
                            <label className="mb-1" style={{ display: 'inline' }}>
                                <strong>PREÇO DE VENDA</strong>
                            </label>
                            <input
                                type="textarea"
                                className="form-control"
                                placeholder="..."
                                value={product.costPrice ? product.costPrice : ''}
                                id="costPrice"
                                onChange={(event) => formHandler(event.target.value, event.target.id)}
                                style={{
                                    width: '100%',
                                    border: '1px solid initial',
                                }}

                            />
                        </div>
                    </div>
                </form>
                <hr style={{ width: '100%' }} />
                <button onClick={() => editProduct( ) } className='btn btn-primary'>
                    EDITAR PRODUTO
                </button>
            </>
        </>
    )
}
export default ProductEdit;