import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { insertProfileInfos , insertProfileInfosStore} from '../../services/UserService';
import bgimage from '../../images/no-img-avatar.png';
import { Modal, Nav, Tab } from 'react-bootstrap';
import tabDataUsers from './subtabs/tabDataUsers.json';

const CadastroForm = () => {
    const [file, setFile] = useState(null);
    const [base64Image, setBase64Image] = useState(null);
    const [errorMessage, setErrorMessage] = useState("");
    const [personData, setPersonData] = useState({
        Name: '',
        Username: '',
        Password: '',
        Email: '',
        Phone: '',
        TaxId: '',
        BirthDate: '',
        Address: {
            Street: '',
            City: '',
            State: '',
            ZipCode: '',
            //Number: 0,
            Neighborhood: ''
        },
        Images: [],
    });

    const [toggle, setToggle] = useState(false);
    const [message, setMessage] = useState(null);
    const [loading, setLoading] = useState(true)
    const [modalMSG, setModalMSG] = useState(false)
    const [itsCustomer, setItsCustomer] = useState(false)

    const handlePersonChange = (e) => {
        const { name, value } = e.target;

        setPersonData((prevData) => {
            if (['Street', 'City', 'State', 'ZipCode', 'Neighborhood', 'Number'].includes(name)) {
                return {
                    ...prevData,
                    Address: {
                        ...prevData.Address,
                        [name]: value,
                    },
                };
            } else {
                return {
                    ...prevData,
                    [name]: value,
                };
            }
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const combinedData = {
            ...personData
        };
        console.log("Payload Enviado:", combinedData);
    };

    const fileHandler = (e) => {
        const file = e.target.files[0];
        if (file && file.size < 1048576) {
            setFile(file);
            const reader = new FileReader();
            reader.onloadend = () => {
                setBase64Image(reader.result);
            };
            reader.readAsDataURL(file)
        } else {
            setErrorMessage("A imagem deve ter menos de 1MB.");
            setFile(null);
            setBase64Image(null);
        }
    };

    const RemoveImage = () => {
        setFile(null);
        setBase64Image(null);
    };

    const registerCustomer = async () => {
        let personMock = JSON.stringify(personData)
        try {
            const fetchEditProfile = async () => {
                try {
                    const response = await insertProfileInfos(personData)
                    if (response.status === 200) {
                        setToggle(false)
                        setModalMSG(true)
                        setMessage('consumidor cadastrado com sucesso!')
                    } else {
                        console.error('Erro ao checar profile:', response.statusText);
                    }
                } catch (error) {
                    console.error('Erro ao checar profile:', error);
                }
            };
            fetchEditProfile();
        } catch (error) {
            console.error('insertProfile o valor de "p" da URL:', error);
        }
    };

    const registerStore = async () => {
        let personMock = JSON.stringify(personData)
        try {
            const fetchEditProfile = async () => {
                try {
                    const response = await insertProfileInfosStore(personData)
                    if (response.status === 200) {
                        setToggle(false)
                        setModalMSG(true)
                        setMessage('consumidor cadastrado com sucesso!')
                    } else {
                        console.error('Erro ao checar profile:', response.statusText);
                    }
                } catch (error) {
                    console.error('Erro ao checar profile:', error);
                }
            };
            fetchEditProfile();
        } catch (error) {
            console.error('insertProfile o valor de "p" da URL:', error);
        }
    };

    function formatDate(isoString) {
        const date = new Date(isoString);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    }

    useEffect(() => {
        if (base64Image) {
            const cleanBase64 = base64Image.replace(/^data:image\/[a-z]+;base64,/, '');

            setPersonData((prevData) => ({
                ...prevData,
                Images: [{
                    Name: 'Imagem Pessoal',
                    Position: 'Profile',
                    IsMain: true,
                    Base64: cleanBase64,
                }]
            }));
        } else {
            setPersonData((prevData) => ({
                ...prevData,
                Images: []
            }));
        }
    }, [base64Image]);

    return (
        <>
            <div className="custom-tab-1 pb-3">
                <Tab.Container defaultActiveKey={tabDataUsers[2].name.toLowerCase()}>
                    <Nav as="ul" className="navigatore justify-content-center">
                        {tabDataUsers.map((data, i) => (
                            <Nav.Item as="li" key={i}>
                                <Nav.Link eventKey={data.name.toLowerCase()} href={data.link}>
                                    <i className={`la la-${data.icon} me-2`} />
                                    {data.name}
                                </Nav.Link>
                            </Nav.Item>
                        ))}
                    </Nav>
                </Tab.Container>
            </div>
            <div className='row mb-4 mt-4 bg-light'>
                <div className='p-3 d-flex align-items-center justify-content-center'>
                    <div className="form-check form-switch">
                        <input className="form-check-input" onClick={() => (setItsCustomer(!itsCustomer) , setPersonData({}) , setBase64Image(null) , setFile(null))} type="checkbox" id="customerCheck" checked={itsCustomer} />
                        <label className="form-check-label text-primary" htmlFor="customerCheck"> É consumidor?</label>
                    </div>
                </div>
            </div>
            {!itsCustomer ?
                <form onSubmit={handleSubmit}>
                    <div className='row'>
                        <div className='col-12'>
                            <br />
                            <h5><i className="bi bi-card-image" style={{ color: '#f54749' }}></i> Imagem de exibição</h5>
                            <hr />
                        </div>
                        <div className='col-12'>

                            <div className="avatar-upload d-flex align-items-center justify-content-center">
                                <div className="change position-relative d-flex">
                                    <div className="avatar-preview" style={{ maxWidth: 120 }}>
                                        {
                                            <img
                                                id="saveImageFile"
                                                src={file ? URL.createObjectURL(file) : bgimage}
                                                alt={file ? file.name : "Imagem Padrão"}
                                                style={{ maxWidth: 120 }}
                                            />
                                        }
                                    </div>
                                    <div className="change-btn d-flex align-items-center flex-wrap">
                                        <input
                                            type='file'
                                            onChange={fileHandler}
                                            className="form-control"
                                            id="imageUpload"
                                            accept=".png, .jpg, .jpeg"
                                        />
                                        <div className="change-btn d-flex flex-column flex-wrap gap-4">
                                            <label htmlFor="imageUpload" className="dlab-upload">{personData && personData.images && personData.images.length > 0 ? ("Escolher Outro Arquivo") : "Escolher Arquivo"}</label>
                                            <Link to={"#"} className="btn remove-img ms-2 bg-light" onClick={RemoveImage}>Remover</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div className='col-12'>
                            <br />
                            <h5><i className="bi bi-person-lines-fill" style={{ color: '#f54749' }}></i> Dados da empresa</h5>
                            <hr />
                        </div>
                        <div className='col-md-6'>
                            <div className="mb-3">
                                <label htmlFor="name" className="form-label">Nome</label>
                                <input
                                    type="text"
                                    name="Name"
                                    value={personData ? personData.name : ''}
                                    onChange={handlePersonChange}
                                    className="form-control"
                                    id="name"
                                />
                            </div>
                            <div className="mb-3">
                                <label htmlFor="email" className="form-label">Email</label>
                                <input
                                    type="email"
                                    name="Email"
                                    value={personData ? personData.email : ''}
                                    onChange={handlePersonChange}
                                    className="form-control"
                                    id="email"
                                />
                            </div>
                        </div>
                        <div className='col-md-6'>
                            <div className="mb-3">
                                <label htmlFor="cpf" className="form-label">CNPJ</label>
                                <input
                                    type="text"
                                    name="TaxId"
                                    value={personData ? personData.taxId : ''}
                                    onChange={handlePersonChange}
                                    className="form-control"
                                    id="cpf"
                                />
                            </div>
                            <div className="mb-3">
                                <label htmlFor="phone" className="form-label">Telefone</label>
                                <input
                                    type="text"
                                    name="Phone"
                                    value={personData ? personData.phone : ''}
                                    onChange={handlePersonChange}
                                    className="form-control"
                                    id="phone"
                                />
                            </div>
                        </div>
                        <div className='col-12'>
                            <br />
                            <h5><i className="bi bi-person-badge" style={{ color: '#f54749' }}></i> Acesso</h5>
                            <hr />
                        </div>
                        <div className='col-md-6'>
                            <div className="mb-3">
                                <label htmlFor="name" className="form-label">Nome de usuário</label>
                                <input
                                    type="text"
                                    name="Username"
                                    value={personData ? personData.username : ''}
                                    onChange={handlePersonChange}
                                    className="form-control"
                                    id="name"
                                />
                            </div>
                        </div>
                        <div className='col-md-6'>
                            <div className="mb-3">
                                <label htmlFor="name" className="form-label">Senha</label>
                                <input
                                    type="text"
                                    name="Password"
                                    value={personData ? personData.password : ''}
                                    onChange={handlePersonChange}
                                    className="form-control"
                                    id="name"
                                />
                            </div>
                        </div>
                        <div className='col-12'>
                            <br />
                            <h5><i className="bi bi-geo-alt" style={{ color: '#f54749' }}></i> Endereço </h5>
                            <hr />
                        </div>
                        <div className='col-sm-6'>
                            <span>
                                <label>Rua:</label>
                                <input
                                    type="text"
                                    name="Street"
                                    value={personData ? personData.Street : ''}
                                    onChange={handlePersonChange}
                                    className="form-control"
                                />
                            </span>
                            <span>
                                <label>Número:</label>
                                <input
                                    type="number"
                                    name="Number"
                                    value={personData ? personData.Number : ''}
                                    onChange={handlePersonChange}
                                    className="form-control"
                                />
                            </span>
                            <span>
                                <label>Bairro:</label>
                                <input
                                    type="text"
                                    name="Neighborhood"
                                    value={personData ? personData.Neighborhood : ''}
                                    onChange={handlePersonChange}
                                    className="form-control"
                                />
                            </span>
                        </div>
                        <div className='col-sm-6'>
                            <span>
                                <label>Cidade:</label>
                                <input
                                    type="text"
                                    name="City"
                                    value={personData ? personData.City : ''}
                                    onChange={handlePersonChange}
                                    className="form-control"
                                />
                            </span>
                            <span>
                                <label>Estado:</label>
                                <input
                                    type="text"
                                    name="State"
                                    value={personData ? personData.State : ''}
                                    onChange={handlePersonChange}
                                    className="form-control"
                                />
                            </span>
                            <span>
                                <label>CEP:</label>
                                <input
                                    type="text"
                                    name="ZipCode"
                                    value={personData ? personData.ZipCode : ''}
                                    onChange={handlePersonChange}
                                    className="form-control"
                                />
                            </span>
                        </div>

                    </div>
                    <div className="change-btn d-flex align-items-center align-content-center bg-light p-3 justify-content-center flex-wrap " style={{ marginTop: 20 }}>
                        <button type="submit" className="btn btn-primary" style={{ maxWidth: 270, width: '100%', margin: '15 auto' }}
                            onClick={() => registerStore()}>Cadastrar</button>
                    </div>
                </form>
                :
                <form onSubmit={handleSubmit}>
                    <div className='row'>
                        <div className='col-12'>
                            <br />
                            <h5><i className="bi bi-card-image" style={{ color: '#f54749' }}></i> Imagem de exibição</h5>
                            <hr />
                        </div>
                        <div className='col-12'>

                            <div className="avatar-upload d-flex align-items-center justify-content-center">
                                <div className="change position-relative d-flex">
                                    <div className="avatar-preview" style={{ maxWidth: 120 }}>
                                        {

                                            <img
                                                id="saveImageFile"
                                                src={file ? URL.createObjectURL(file) : bgimage}
                                                alt={file ? file.name : "Imagem Padrão"}
                                                style={{ maxWidth: 120 }}
                                            />

                                        }

                                    </div>
                                    <div className="change-btn d-flex align-items-center flex-wrap">
                                        <input
                                            type='file'
                                            onChange={fileHandler}
                                            className="form-control"
                                            id="imageUpload"
                                            accept=".png, .jpg, .jpeg"
                                        />
                                        <div className="change-btn d-flex flex-column flex-wrap gap-4">
                                            <label htmlFor="imageUpload" className="dlab-upload">{personData && personData.images && personData.images.length > 0 ? ("Escolher Outro Arquivo") : "Escolher Arquivo"}</label>
                                            <Link to={"#"} className="btn remove-img ms-2 bg-light" onClick={RemoveImage}>Remover</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div className='col-12'>
                            <br />
                            <h5><i className="bi bi-person-lines-fill" style={{ color: '#f54749' }}></i> Dados Pessoais</h5>
                            <hr />
                        </div>
                        <div className='col-md-6'>
                            <div className="mb-3">
                                <label htmlFor="name" className="form-label">Nome</label>
                                <input
                                    type="text"
                                    name="Name"
                                    value={personData ? personData.name : ''}
                                    onChange={handlePersonChange}
                                    className="form-control"
                                    id="name"
                                />
                            </div>
                            <div className="mb-3">
                                <label htmlFor="birthDate" className="form-label">Data de Nascimento</label>
                                <input
                                    type="date"
                                    name="BirthDate"
                                    value={personData ? personData.birthDate : ''}
                                    onChange={handlePersonChange}
                                    className="form-control"
                                    id="birthDate"
                                />
                            </div>
                            <div className="mb-3">
                                <label htmlFor="email" className="form-label">Email</label>
                                <input
                                    type="email"
                                    name="Email"
                                    value={personData ? personData.email : ''}
                                    onChange={handlePersonChange}
                                    className="form-control"
                                    id="email"
                                />
                            </div>
                        </div>
                        <div className='col-md-6'>
                            <div className="mb-3">
                                <label htmlFor="cpf" className="form-label">CPF</label>
                                <input
                                    type="text"
                                    name="TaxId"
                                    value={personData ? personData.taxId : ''}
                                    onChange={handlePersonChange}
                                    className="form-control"
                                    id="cpf"
                                />
                            </div>
                            <div className="mb-3">
                                <label htmlFor="phone" className="form-label">Telefone</label>
                                <input
                                    type="text"
                                    name="Phone"
                                    value={personData ? personData.phone : ''}
                                    onChange={handlePersonChange}
                                    className="form-control"
                                    id="phone"
                                />
                            </div>
                        </div>
                        <div className='col-12'>
                            <br />
                            <h5><i className="bi bi-person-badge" style={{ color: '#f54749' }}></i> Acesso</h5>
                            <hr />
                        </div>
                        <div className='col-md-6'>
                            <div className="mb-3">
                                <label htmlFor="name" className="form-label">Nome de usuário</label>
                                <input
                                    type="text"
                                    name="Username"
                                    value={personData ? personData.username : ''}
                                    onChange={handlePersonChange}
                                    className="form-control"
                                    id="name"
                                />
                            </div>
                        </div>
                        <div className='col-md-6'>
                            <div className="mb-3">
                                <label htmlFor="name" className="form-label">Senha</label>
                                <input
                                    type="text"
                                    name="Password"
                                    value={personData ? personData.password : ''}
                                    onChange={handlePersonChange}
                                    className="form-control"
                                    id="name"
                                />
                            </div>
                        </div>
                        <div className='col-12'>
                            <br />
                            <h5><i className="bi bi-geo-alt" style={{ color: '#f54749' }}></i> Endereço </h5>
                            <hr />
                        </div>
                        <div className='col-sm-6'>
                            <span>
                                <label>Rua:</label>
                                <input
                                    type="text"
                                    name="Street"
                                    value={personData ? personData.Street : ''}
                                    onChange={handlePersonChange}
                                    className="form-control"
                                />
                            </span>
                            <span>
                                <label>Número:</label>
                                <input
                                    type="number"
                                    name="Number"
                                    value={personData ? personData.Number : ''}
                                    onChange={handlePersonChange}
                                    className="form-control"
                                />
                            </span>
                            <span>
                                <label>Bairro:</label>
                                <input
                                    type="text"
                                    name="Neighborhood"
                                    value={personData ? personData.Neighborhood : ''}
                                    onChange={handlePersonChange}
                                    className="form-control"
                                />
                            </span>
                        </div>
                        <div className='col-sm-6'>
                            <span>
                                <label>Cidade:</label>
                                <input
                                    type="text"
                                    name="City"
                                    value={personData ? personData.City : ''}
                                    onChange={handlePersonChange}
                                    className="form-control"
                                />
                            </span>
                            <span>
                                <label>Estado:</label>
                                <input
                                    type="text"
                                    name="State"
                                    value={personData ? personData.State : ''}
                                    onChange={handlePersonChange}
                                    className="form-control"
                                />
                            </span>
                            <span>
                                <label>CEP:</label>
                                <input
                                    type="text"
                                    name="ZipCode"
                                    value={personData ? personData.ZipCode : ''}
                                    onChange={handlePersonChange}
                                    className="form-control"
                                />
                            </span>
                        </div>

                    </div>
                    <div className="change-btn d-flex align-items-center align-content-center bg-light p-3 justify-content-center flex-wrap " style={{ marginTop: 20 }}>
                        <button type="submit" className="btn btn-primary" style={{ maxWidth: 270, width: '100%', margin: '15 auto' }}
                            onClick={() => registerCustomer()}>Cadastrar</button>
                    </div>
                </form>
            }
            <Modal show={modalMSG} onHide={setModalMSG} centered>
                <button onClick={() => setModalMSG(false)} className='btn btn-light p-1' style={{ width: 30, height: 30, position: 'absolute', right: 6, top: 6, borderRadius: '100%' }}>
                    <i className="bi bi-x-circle" style={{ fontSize: 14 }}></i>
                </button>
                <div className='d-flex align-items-center justify-content-center flex-column p-4' style={{ minHeight: 300 }}>
                    <h2 style={{ paddingBottom: 3 }}><i class="bi bi-exclamation-triangle"></i></h2>
                    <h4 style={{ paddingBottom: 7 }}>{message}</h4>
                </div>
            </Modal>
        </>
    );
};

export default CadastroForm;